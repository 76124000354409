import withRoot from "../utils/withRoot";
import React, { useMemo } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { Typography, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classnames from 'classnames';
import SEO from "../components/SEO";
import ProfileImageSmall from '../components/ProfileImageSmall';
import { getDurationFromDate, getFSDate } from '../utils/formatters';
import Page from '../components/Page';
import {
  FileDownload as DownloadIcon,
  React as ReactIcon,
  Nodejs as NodeJsIcon,
  Github as GithubIcon } from 'mdi-material-ui';
import ExternalLink, { RESOURCE_TYPES } from '../components/ExternalLink';

const useStyles = makeStyles(theme => ({
  infoGridItem: {
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto'
    }
  },
  header: {
    [theme.breakpoints.up('md')]: {
      position: 'sticky',
      top: '30%'
    }
  },
  headerContainer: {
    marginBottom: theme.spacing.unit * 2,
    [theme.breakpoints.up('md')]: {
      minHeight: '100vh'
    }
  },
  profileImageContainer: {
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto'
    }
  },
  profileImage: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    [theme.breakpoints.up('md')]: {
      position: 'sticky',
      top: 'calc(50% - 200px)'
    }
  },
  sectionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  section: {
    width: '100%',
    maxWidth: '800px',
    padding: theme.spacing.unit * 2
  },
  inlineIcon: {
    verticalAlign: 'text-bottom'
  },
  learnMoreButton: {
    marginTop: theme.spacing.unit * 2
  },
  button: {
    [theme.breakpoints.up('sm')]: {
      '&:not(:last-child)': {
        marginRight: theme.spacing.unit * 2
      },
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      '&:not(:last-child)': {
        marginBottom: theme.spacing.unit * 2
      },
    }
  },
  buttonIcon: {
    marginLeft: theme.spacing.unit
  }
}));

function MainPage() {
  const {
    info: {
      childAssetsJson: {
        name,
        greeting,
        whoAreYouInOneSentence,
        experienceSince,
        contacts
      }
    },
    cv: {
      publicURL: cvPublicURL,
      modifiedTime: cvModifiedTime,
      extension: cvExtension
    }
  } = useStaticQuery(graphql`
    query {
      info: file(relativePath: { eq: "info.json" }, sourceInstanceName: { eq: "assets" }) {
        childAssetsJson {
          greeting
          name
          whoAreYouInOneSentence
          experienceSince
          contacts {
            email
            skype
            github
            npmjs,
            twitter,
            instagram,
            linkedin
          }
        }
      }
      cv: file(relativePath: { eq: "files/CV.pdf" }, sourceInstanceName: { eq: "assets" }) {
        publicURL
        modifiedTime
        extension
      }
    }
  `);
  const classes = useStyles();
  const rangeOfExperience = useMemo(() => getDurationFromDate(experienceSince), [experienceSince]);

  return (
    <Page>
      <SEO
        meta={[
          {
            name: 'description',
            content: `${name} - ${whoAreYouInOneSentence}`,
          },
        ]}
      />
      <Grid container className={classes.headerContainer}>
        <Grid item xs={12} md={6} className={classes.profileImageContainer}>
          <ProfileImageSmall className={classes.profileImage} />
        </Grid>
        <Grid item md={6} className={classes.infoGridItem}>
          <header className={classes.header}>
            <Typography variant="h4" component="h1">
              {greeting}
            </Typography>
            <Typography variant="body1">
              {whoAreYouInOneSentence}
            </Typography>
          </header>
        </Grid>
      </Grid>
      <div className={classes.sectionsContainer}>
        <section className={classes.section} id="experience_summary">
          <Typography variant="body1"> 
            {`I have been writing production Web Apps using `}
            React<ReactIcon color="primary" className={classes.inlineIcon} />
            {` and `}
            Node<NodeJsIcon color="secondary" className={classes.inlineIcon} />
            {` stack for ${rangeOfExperience}. `}
          </Typography>
          <Button
            className={classnames(classes.learnMoreButton, classes.button)}
            component={Link}
            to="/experience"
            color="primary"
            variant="outlined"
          >
            Learn more
          </Button>
        </section>
        <section className={classes.section} id="features">
          <Typography variant="body1" component="span"> 
            <ul>
              <li>
                I have Business Analysis Experience and strong technical knowledge
              </li>
              <li>
                I write clean code, refactor code, apply test driven development and practice peer code reviews and pair programming
              </li>
              <li>
                I've successfully completed multiple projects in a fast-paced environment of growing startup companies
              </li>
            </ul>
          </Typography>
        </section>
        <section className={classes.section} id="code_samples">
          <Button
            className={classes.button}
            href={`https://github.com/${contacts.github}?tab=repositories`}
            color="primary"
            variant="outlined"
            id="code_samples"
          >
            View code samples
            <GithubIcon className={classes.buttonIcon} />
          </Button>
          <Button
            className={classes.button}
            href={cvPublicURL}
            color="primary"
            variant="outlined"
            download={`${name} CV ${getFSDate(cvModifiedTime)}.${cvExtension}`}
            id="cv"
          >
            Download CV
            <DownloadIcon className={classes.buttonIcon} />
          </Button>
        </section>
        <section className={classes.section} id="contacts">
          <Typography variant="h5" component="h2"> 
            Let's get in touch:
          </Typography>
          <Typography variant="body1" component="span"> 
            <ul>
              <li>
                {`Email me: `}
                <ExternalLink type={RESOURCE_TYPES.EMAIL} value={contacts.email} color="primary" />
              </li>
              {!!contacts.skype && (<li>
                {`Call or text me: `}
                <ExternalLink type={RESOURCE_TYPES.SKYPE} value={contacts.skype} color="secondary" />
              </li>)}
              <li>
                {`Find me on GitHub: `}
                <ExternalLink type={RESOURCE_TYPES.GITHUB} value={contacts.github} color="secondary" />
              </li>
              <li>
                {`Find me on npm: `}
                <ExternalLink type={RESOURCE_TYPES.NPMJS} value={contacts.npmjs} color="secondary" />
              </li>
              <li>
                {`Find me on linkedin: `}
                <ExternalLink type={RESOURCE_TYPES.LINKEDIN} value={contacts.linkedin} color="secondary" />
              </li>
              <li>
                {`Find me on twitter: `}
                <ExternalLink type={RESOURCE_TYPES.TWITTER} value={contacts.twitter} color="secondary" />
              </li>
              <li>
                {`Find me on instagram: `}
                <ExternalLink type={RESOURCE_TYPES.INSTAGRAM} value={contacts.instagram} color="secondary" />
              </li>
            </ul>
          </Typography>
        </section>
      </div>
    </Page>
  );
}

export default withRoot(MainPage)
