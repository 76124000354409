import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { Hidden } from '@material-ui/core';

function ProfileImage({ className }) {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/profile_face.jpg" }, sourceInstanceName: { eq: "assets" }) {
        childImageSharp {
          fixed(height: 400) {
            ...GatsbyImageSharpFixed
          },
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div className={className}>
      <Hidden xsDown>
        <Img
          fixed={data.file.childImageSharp.fixed}
          alt="Me"
        />
      </Hidden>
      <Hidden smUp>
        <Img
          fluid={data.file.childImageSharp.fluid}
          alt="Me"
        />
      </Hidden>
    </div>
  );
}

export default ProfileImage;
